// -------------------------------------------------------------------
// :: WIDTH - HEIGHT
// -------------------------------------------------------------------

.full-width {
    width: 100%;
}

.full-height {
    height: 100%;
}

.full-width-height {
    width: 100%;
    height: 100%;
}

.full-view-height {
    height: 100vh;
}

.full-view-width {
    height: 100vw;
}

.full-view-width-heigth {
    height: 100vh;
    width: 100vw;
}

.contain {
    width: fit-content;
}

// -------------------------------------------------------------------
// :: PADDING - MARGIN
// -------------------------------------------------------------------

.margin-lg {
    margin: $spacer-lg;
}

.margin-top-lg {
    margin-top: 10%;
}

.margin-top-sm {
    margin-top: 3%;
}

.margin-bottom-lg {
    margin-bottom: $spacer-lg;
}

.margin-auto {
    margin-right: auto;
    margin-left: auto;
}