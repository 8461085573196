.marker {
  background-image: url('../../assets/marker-black.png');
  background-size: cover;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  cursor: pointer;

  &-toast {
    background: $dark-blue;
  }
}