.grid {
    display: grid;
}

.grid-two-col {
    grid-template-columns: repeat(2, 50%);
    grid-template-rows: 100%;
}

.grid-center {
    align-items: center;
}