.link-icon {
    height: 20px;
    width: 20px;
    margin: 0 10px;
}

.icon-sidebar {
    position: absolute;
    font-size: 20px;

    &-top {
        right: 0;
        top: 0;
    }

    &-middle {
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }

    &-bottom {
        right: 0;
        bottom: 0;
        color: $red;
    }
}

.icon-download {
    font-size: 20px;
    margin-left: $spacer-md;
}