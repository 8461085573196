.setting {
    width: 80%;
    padding: 10px;
    border-bottom: $border-sm $light-grey;
    padding: $spacer-lg;

    .content {
        width: 70%;
        margin: 0;
    }

    .value {}

}