.absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.top {
    top: 0;
}

.right {
    right: 0;
}

.bottom {
    bottom: 0;
}

.left {
    left: 0;
}