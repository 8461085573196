// -------------------------------------------------------------------
// :: COLORS
// -------------------------------------------------------------------

$white: #ffffff;
$light-grey: #efefef;
$grey: #a2a2a2;
$dark-grey: #707070;
$black: #000000;
$green: #A6DD5B;
$red: #dd5b5b;
$light-blue: #00BCF2;
$dark-blue: #009DDC;

// -------------------------------------------------------------------
// :: BOX
// -------------------------------------------------------------------

$font-family: "Poppins";

// -------------------------------------------------------------------
// :: BOX
// -------------------------------------------------------------------

$box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);

// -------------------------------------------------------------------
// :: FONTS SIZE
// -------------------------------------------------------------------

$font-xs: 10px;
$font-sm: 12px;
$font-md: 14px;
$font-lg: 16px;
$font-xl: 18px;
$font-xxl: 20px;

// -------------------------------------------------------------------
// :: FONTS WEIGHT
// -------------------------------------------------------------------

$font-thin: 100;
$font-light: 200;
$font-regular: 400;
$font-bold: 600;
$font-bolder: 800;
$font-black: 1200;
$font-xblack: 1400;

// -------------------------------------------------------------------
// :: Z-INDEX
// -------------------------------------------------------------------

$bottom: 0;
$overlay: 1;
$menu: 2;
$top: 3;

// -------------------------------------------------------------------
// :: BORDER
// -------------------------------------------------------------------

$border-radius-xs: 5px;
$border-radius-sm: 10px;
$border-radius-md: 15px;
$border-radius-lg: 20px;

$border-sm: 1px solid;
$border-md: 2px solid;
$border-lg: 3px solid;

// -------------------------------------------------------------------
// :: SPACER
// -------------------------------------------------------------------

$spacer-xs: 5px;
$spacer-sm: 10px;
$spacer-md: 15px;
$spacer-lg: 20px;