.search-results {
	width: 100%;
	top: 50px;
	background-color: $white;
	padding: $spacer-sm $spacer-md;
	border-bottom-left-radius: $border-radius-md;
	border-bottom-right-radius: $border-radius-md;


	.result {
		padding: $spacer-xs;
		border-radius: $border-radius-xs;

		&:hover {
			background-color: $light-grey;
		}
	}
}